import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import './UserDetails.css';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import Paper from '@material-ui/core/Paper';


const UserDetails = ({ bookingData, setBookingData, bookingState, setBookingState, payType }) => {
  const history = useHistory();
  const [email, setEmail] = useState(bookingData.email);
  const [vehicleRegistration, setVehicleRegistration] = useState(bookingData.vehicleRegistration);
  const [phoneNumber, setPhoneNumber] = useState(bookingData.phoneNumber);
  //console.log(bookingData);
  const handleSubmit = (e) => {
    e.preventDefault();
    if (vehicleRegistration.length<4) {
      alert("Invalid vehicle registration. please correct.");
      return;
    }
    
    //console.log(bookingData);
    setBookingData({ ...bookingData, email, vehicleRegistration, phoneNumber});
    //setBookingState({current:"paymentdonation", prev:bookingState.prev});
    setBookingState({current:"bookingsummary", prev:bookingState.prev});
  };
  const handleBack = () => {
    //history.push('/calendar');
    //console.log(bookingData);
    if (payType=="overnight") {
      setBookingData({ ...bookingData, email, vehicleRegistration, phoneNumber});
    } else {
      setBookingData({ ...bookingData, email, vehicleRegistration, phoneNumber, selectedDates:[]});
    }
    setBookingState({current:bookingState.prev, prev:bookingState.prev});

  };

const handleSetReg = (e) => {
  e.preventDefault();
  //console.log(">"+e.target.value+"<");
  //console.log(">"+e.target.value.trim()+"<");
  let val = e.target.value.trim().toUpperCase();
  val = val.replace(/[^A-Z0-9\s]/g, '');

  //pattern="[a-zA-Z0-9]+"
  //if (e.target.value.trim()>0) {
    setVehicleRegistration(val);
  //}
}


  return (
    <Paper variant="outlined" >
    <div className="user-details">
             <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          p: 0,
          m: 0,
          borderRadius: 1,
          alignContent: 'center',
        }}
      >
        <Box><h4>Your Details</h4></Box>
        <Box  mt={-0.4}>
        <Button
         size="small"
        color="primary"
        onClick={handleBack}
        startIcon={<ArrowBackIcon />}
      >Back
      </Button>
      </Box>
      </Box>
 





      <div className="details">
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="email">Email Address*</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="phoneNumber">Phone Number</label>
          <input
            type="text"
            id="phoneNumber"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
          
          />
        </div>
        <div className="form-group">
          <label htmlFor="vehicleRegistration">Vehicle Registration*</label>
          <input
            type="text"
            id="vehicleRegistration"
            value={vehicleRegistration}
            onChange={handleSetReg}
            maxLength={10}
            minLength={3}
            required
          />
        </div>
        {payType === 'overnight' ?
        <button type="submit" className="submit-btn">Create Booking</button>
        :
        <button type="submit" className="submit-btn">Create Services Payment</button>
        }
      </form>
      </div></div>
      </Paper>
  );
};

export default UserDetails;
