import React, { useState, useEffect } from "react";
import { API } from "aws-amplify";


//import "./ListData.css";

import { useAppContext } from "../libs/contextLib";
import { useHistory, useParams } from "react-router-dom";

import image from'../images/thinking.jpg';
import iCampra from'../images/campra/Campra Accredited - Green.svg';

import TextField from '@material-ui/core/TextField';
import LoaderButtonX from "../components/LoaderButtonX";
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';


import spinner from'../images/Driving-SVG.gif';


import { Link } from 'react-router-dom';


import { makeStyles, withStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';

import Button from '@material-ui/core/Button';

const FooterTypography = withStyles({
  root: {
    color: "#fff"
  }
})(Typography);


//const columns = createCols();
/*
function createCols() {
  //console.log(id);
  let cols = [
    { id: 'timestamp',
      label: 'Timestamp',
      minWidth: 180,
      format: (value) => (new Date((Number(value)*1000) - 3600000)).toLocaleString('en-GB'),
    },

  ];
  return cols;
}
  */




export default function Events() {
  /*
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  */


  const { id } = useParams();
  const [rows, setRows] = useState([]);
  const { isAuthenticated } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  const columns = createCols();
  const [expressAccountId, setExpressAccountId] = useState("");



  const useViewport = () => {
    const [width, setWidth] = React.useState(window.innerWidth);
    const [height, setHeight] = React.useState(window.innerHeight);
    React.useEffect(() => {
      const handleWindowResize = () => {setWidth(window.innerWidth);setHeight(window.innerHeight);};
      window.addEventListener("resize", handleWindowResize);
      return () => window.removeEventListener("resize", handleWindowResize);
    }, []);
    return { width, height };
  }
  const { width, height } = useViewport();



useEffect(() => {
  async function onLoad() {
    if (!isAuthenticated) {history.push("/"); return;}
    setIsLoading(true);
    const result = await getEventData();
    
    result.data.forEach(row => {
      row.content["createdAt"] = row.createdAt;
      row.content["combinedSiteName"] = row.content.siteid?row.content.sitename + " (" + row.content.siteid + ")":"";
      if(row.content.type) {
        if (row.content.type=="SITECLAIM") {
          row.content.type="CLAIM";
        }
      } else {
        if (row.content.userid && row.content.userid!="") {
          row.content["type"]="Message to Host";
        } else {
          if (row.content.feedback.includes("CAMpRA Reference")) {
            row.content["type"]="Referred from CAMpRA!";
          } else {
            row.content["type"]="Query";
          }
        }
      }
    });
    //console.log(result.data.content);
    setRows(result.data);
    setIsLoading(false);
    
  }
  onLoad();
}, []);

function getEventData() {
    return API.get("aires", `/events`);
}

function deleteExpressAccount(expressId) {
  return API.post("aires", "/customers", {
    body: {type: 'delete-express-account', content: {expressId: expressId}}
  });
}

async function deleteAccount(event) {
  event.preventDefault();
  const result = await deleteExpressAccount(expressAccountId);
  setExpressAccountId("");
  console.log(result);
}
async function handleDeleteAccountChange(event) {
  event.preventDefault();
  console.log("handleDeleteAccountChange");
  setExpressAccountId(event.target.value);
}


function createCols() {
    //console.log(id);
    //const colcount = id=='S0003'?128:64;
    
    let cols = [
      { id: 'createdAt',
        label: 'Timestamp',
        minWidth: 80,
        format: (value) => (new Date((Number(value)*1))).toLocaleString('en-GB'),
      },
      { id: 'type',
        label: 'Type',
        minWidth: 50,
        align: 'left',
        format: (value) => value,
      },
      { id: 'combinedSiteName',
        label: 'Site',
        minWidth: 80,
        align: 'left',
        format: (value) => value,
      },
      { id: 'name',
        label: 'User Name',
        minWidth: 40,
        align: 'left',
        format: (value) => value,
      },
      { id: 'email',
        label: 'Email',
        minWidth: 40,
        align: 'left',
        format: (value) => value,
      },
      { id: 'feedback',
        label: 'Message',
        minWidth: 200,
        align: 'left',
        format: (value) => value,
      },
      { id: 'userid',
        label: 'Host ID',
        minWidth: 40,
        align: 'left',
        format: (value) => value,
      },
    ];
    return cols;
  }

  const downloadCSV = (data, fileName) => {
    const csvData = new Blob([convertToCSV(data)], { type: 'text/csv' });
    const csvURL = URL.createObjectURL(csvData);
    const link = document.createElement('a');
    link.href = csvURL;
    link.download = `${fileName}.csv`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const convertToCSV = (objArray) => {
    const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
    let str = '';
//console.log(array);
    for (let i = 0; i < array.length; i++) {
      let line = '';
      for (let index in array[i]) {
        if (line !== '') line += ',';

        line += array[i][index];
      }
      str += line + '\r\n';
    }
    return str;
  };


  function getSitesByAdmin() {
    //console.log("getSitesByAdmin");
    return API.post("aires", `/tranadmin`, {
      body: { sitesonly: true }
    });
  }

  function getHosts() {
    return API.post("aires", `/tranadmin`, {
      body: { hostsonly: true }
    });
  }



  function handleTransactions(event) {
    event.preventDefault();
    history.push("/admin/transactions");
  }

  

  async function processCsvHostList(event) {
    event.preventDefault();
    setIsLoading(true);
    const datestr = (new Date(Date.now())).toLocaleString();
    const optionsShort = {year: '2-digit', month: '2-digit', day: '2-digit' };    
    let dataForHostCSV = [];
    const data = await getHosts(); 
    //console.log(data.hosts);
    if (data.status && data.hosts) {
      const sortedHosts = data.hosts.sort((a, b) => {
          return a.userName.localeCompare(b.userName);
      });
      dataForHostCSV.push({name:"Host name", email:"Email", phone:"Phone", userid:"User ID", stripe: "Stripe ID", timestamp: "Created date"});
      sortedHosts.forEach(host => {
        const date = new Date(host.createdAt);
        dataForHostCSV.push({name:host.userName, email:host.email, phone:host.phone, userid: host.pk, stripe: host.itemId, timestamp: date.toLocaleDateString(undefined, optionsShort)});
      });
      downloadCSV(dataForHostCSV, "HostDetails_" + datestr);
    }
    setIsLoading(false);
  }

  async function processCsvSiteList(event) {
    event.preventDefault();
    setIsLoading(true);
    const datestr = (new Date(Date.now())).toLocaleString();
    const optionsShort = {year: '2-digit', month: '2-digit', day: '2-digit' };
    const result = await getSitesByAdmin();
  
  
    const hosts = result.data.hosts;
  
  
    const sites = result.data.sites.sort(
             //return a.itemStatus<=b.itemStatus;
             function(a, b){return a.itemStatus - b.itemStatus}
         );
  
         
  
         sites.forEach(site=>{
          for (let i=0;i<hosts.length;i++) {
            if (hosts[i].pk==site.pk) {
              site["host"] = hosts[i];
              break;
            }
          }
         })
        
         
         
         
         
         let dataForCSV = [];
         dataForCSV.push({isAireStop: "Airestop", status:"Status",siteName: "Site name", siteCode:"Site code", bays: "Pitches", priceStay: "Price Stay", priceFacilities: "Price Facilities", facilities: "Facilities", hostName:"Host name", email:"Email", phone:"Phone", createdDate: "Created on"});
         sites.forEach(site=>{
          const date = new Date(site.host.createdAt);
          let facilities = [];
          const keys = Object.keys(site.content.facilities);
          keys.forEach(key=>{
            if (site.content.facilities[key]==true) {
              facilities.push(key);
            }
            });
   
  
          if (site.itemStatus==8) {
            dataForCSV.push({isAireStop: "false", status:"non-airestop",siteName: "\"" + site.content.siteName + "\"" , siteCode:site.itemCode, bays: site.content.bays, priceStay: site.content.priceStay, priceFacilities: site.content.priceFacilities, facilities: "\"" + facilities + "\"", hostName:"n/a", email:"n/a", phone:"n/a", createdDate: "n/a"});
          } else {
            dataForCSV.push({isAireStop: "true", status:site.itemStatus==0?"closed":(site.itemStatus==1?"open":(site.itemStatus==2?"calendar":(site.itemStatus==9?"defunct":"unknown"))),siteName: "\"" + site.content.siteName + "\"" , siteCode:site.itemCode, bays: site.content.bays, priceStay: site.content.priceStay, priceFacilities: site.content.priceFacilities, facilities: "\"" + facilities + "\"", hostName:site.host.userName, email:site.host.email, phone:site.host.phone, createdDate: date.toLocaleDateString(undefined, optionsShort)});
          }
          
         });
    
         downloadCSV(dataForCSV, "Sites_" + datestr);
         setIsLoading(false);
  }

  function renderLander() {
    return (
      <div className={"lander"} >
        <h2 color="secondary">Manager:</h2>
        <Box height="16px"/>

        <Box my={2}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      width="100%"

                    >
                      <Box p={2}><Button isLoading={isLoading} align="center" variant="contained" color="secondary" onClick={handleTransactions}>Transactions reporter</Button></Box>
                      <Box p={2}><Button isLoading={isLoading} align="center" variant="contained" color="secondary" onClick={processCsvSiteList}>Create site List (CSV)</Button></Box>
                      <Box p={2}><Button isLoading={isLoading} align="center" variant="contained" color="secondary" onClick={processCsvHostList}>Create host List (CSV)</Button></Box>

<TextField id="delacc"
  value={expressAccountId}
  onChange={handleDeleteAccountChange}
  name="delacc"
  type="text" variant="outlined" size="small"/>
  <Box p={2}><Button isLoading={isLoading} align="center" variant="contained" color="secondary" disabled={expressAccountId.length<20 || !expressAccountId.includes("acct_")} onClick={deleteAccount}>Delete Stripe Express Account</Button></Box>


                    </Box>


        <h4 color="secondary"><b>{'Notifications'}</b></h4>
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer style={{ maxHeight: `calc(100vh - 210px)` }}>
            <Table stickyHeader aria-label="sticky table" size={'small' }>
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth, background:"#f0c239", fontWeight:"bold" }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={row.createdAt}  >
                        {columns.map((column) => {
                          const value = row.content[column.id];
                          const w = column.minWidth;
                          return (
                            <TableCell key={column.id} align={column.align} 
                            style={column.id=="type" && (value=="CLAIM" || value=="Referred from CAMpRA!" || value=="Query")?{minWidth:w,color:"#FF7777", fontWeight:"bold" }:{minWidth:w}}>
                              {column.format(value)}
                            </TableCell>
                          );
                        })}
                      </TableRow>                    );
                  })}
              </TableBody>
            </Table>
            </TableContainer>
        </Paper>
      </div>
    );
  }

  function renderLoader() {
    return (
      <div id="splash" className={"lander"} style={{paddingTop: (height-100)/2, zIndex:999}}>

        <center id="splash">
          <img src={spinner} height="30px" width="auto"/>
        </center>
      </div>


    );

  }


  return (
    <div className="ListData">
      {isLoading?renderLoader():renderLander()}
    </div>
  );
}
