import React, { useState, useEffect } from "react";
import { API, Auth } from "aws-amplify";
import "./Home.css";
//import LoaderButton from "../components/LoaderButton";

import { useAppContext } from "../libs/contextLib";
import { useHistory } from "react-router-dom";
//import bgimage from'../images/large_camper.jpeg';
import spinner from'../images/Driving-SVG.gif';
import campraImage from'../images/large_camper_wide.jpeg';


import TextField from '@material-ui/core/TextField';
import LoaderButtonX from "../components/LoaderButtonX";
import SiteIdInput from "../components/SiteIdInput";

import { useFormFields } from "../libs/hooksLib";
import { makeStyles, withStyles } from '@material-ui/core/styles';

import Form from "react-bootstrap/Form";
import FormControl from '@material-ui/core/FormControl';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import AccountBalanceRoundedIcon from '@material-ui/icons/AccountBalanceRounded';
import FollowTheSignsRoundedIcon from '@material-ui/icons/StorefrontRounded';
//import StorefrontRoundedIcon from '@mui/icons-material/StorefrontRounded';
//import SettingsBackupRestoreRoundedIcon from '@mui/icons-material/SettingsBackupRestoreRounded';
import LocalParkingRoundedIcon from '@material-ui/icons/LocalParkingRounded';
import HowToRegRoundedIcon from '@material-ui/icons/HowToRegRounded';
import RestartAltRoundedIcon from '@material-ui/icons/SettingsBackupRestoreRounded';
//import AddLocationAltRoundedIcon from '@material-ui/icons/AddLocationAltRounded';

import { Link } from 'react-router-dom';



import QrReader from 'react-qr-reader'
import Fab from '@material-ui/core/Fab';

import Divider from '@material-ui/core/Divider';


import HeroSectionSiteSearch from "../components/HeroSectionSiteSearch";
import FeatureSectionHowItWorks from "../components/FeatureSectionHowItWorks";
import FeatureSectionFeaturedSites from "../components/FeatureSectionFeaturedSites";
import TestimonialSectionGuests from "../components/TestimonialSectionGuests";
import FooterPublicLrg from "../components/FooterPublicLrg";
import styles from "./LocoHomeMotoroamer.module.css";



const FooterTypography = withStyles({
  root: {
    color: "#fff"
  }
})(Typography);



export default function Home() {
  const history = useHistory();
  const { userHasAuthenticated, isAuthenticated, isCampra, setShowSites} = useAppContext();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingContReg, setIsLoadingContReg] = useState(false);
  const [isLoadingReReg, setIsLoadingReReg] = useState(false);
  const [isLoadingPayments, setIsLoadingPayments] = useState(false);
  const [isCheckingAccount, setIsCheckingAccount] = useState(false);
  const [isScanning, setIsScanning] = useState(false);
  const [scanResult, setScanResult] = useState("");
  const [pauseScan, setPauseScan] = useState(false);
  const [accountId, setAccountId] = useState("");
  const [email, setEmail] = useState("");
  const [requiresAccountDetails, setRequiresAccountDetails] = useState(false);
  const [canResetAccount, setCanResetAccount] = useState(false);
  const [canShowSites, setCanShowSites] = useState(false);
  const [addFirstSite, setAddFirstSite] = useState(false);
  const [orderSigns, setOrderSigns] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertRestart, setAlertRestart] = useState(false);
  const [alertOnboarding, setAlertOnboarding] = useState(false);
  const [fields, handleFieldChange] = useFormFields({
    siteid: "",
    reg: "",
  });
  const [alertVals, handleAlertChange] = useFormFields({
    title: "",
    message: "",
  });

  const useViewport = () => {
    //console.log(window.innerWidth);
    const [width, setWidth] = React.useState(window.innerWidth);

    React.useEffect(() => {
      const handleWindowResize = () => setWidth(window.innerWidth);
      window.addEventListener("resize", handleWindowResize);
      return () => window.removeEventListener("resize", handleWindowResize);
    }, []);

    // Return the width so we can use it in our components
    return { width };
  }

  const [featuredSites, setFeaturedSites] = useState([]);
  const [feedback, setFeedback] = useState([]);

  const { width } = useViewport();
  const breakpoint = 600;

  const CLIENT_ID_LIVE = "ca_JmGQd26HQIoYn53CydCzITWOd6yRe3Xj";
  const CLIENT_ID_TEST_MODE = "ca_JmGQMswLTEvTAAGMrW0rLu0OVqmgiKw2";

  const IS_TEST_MODE = false;

  /*
  function lock(orientation) {
  let de = document.documentElement;
  if (de.requestFullscreen) { de.requestFullscreen(); }
  else if (de.mozRequestFullScreen) { de.mozRequestFullScreen(); }
  else if (de.webkitRequestFullscreen) { de.webkitRequestFullscreen(); }
  else if (de.msRequestFullscreen) { de.msRequestFullscreen(); }
  ScreenOrientation.lock(orientation);
}
*/

useEffect(() => {

  async function onLoad() {
    if (!isAuthenticated) {history.push("/"); return;}
    //lock("portrait");
    setIsCheckingAccount(true);
    const cui = await Auth.currentUserInfo();
    if (!(cui && cui.attributes)) {
      userHasAuthenticated(false);
      history.push("/");
    } else {
      const params = new URLSearchParams(window.location.search);
      const code = params.get("code");
      const reauth = params.get("reauth");
      console.log(window.location.href);
      const resultUser = await createUser({userName: cui.attributes['custom:name'], phone: cui.attributes['custom:phone'], email: cui.attributes.email});
      setEmail(resultUser.user.email);
      let accId = resultUser.user.itemId;
      if (accId && accId!="") {
        if (reauth) {
          const resultLink = await linkCustomer({id: accId, url: window.location.protocol + "//" + window.location.host});
          let url = resultLink.accountLinks.url;
          if (url) {
            window.location.href = url;
          }
          return;
        }
        continueOnboarding(accId);
      } else if (code) {
        const result = await onboardExisting({code: code, istestmode: IS_TEST_MODE});
        console.log(result.account);
        if (!result.account.error && result.account.id) {
          continueOnboarding(result.account.id);
        } else {
          setRequiresAccountDetails(true);
          setCanShowSites(false);
          setShowSites(false);
          setCanResetAccount(false);  
        }
      } else {
        setRequiresAccountDetails(true);
        setCanShowSites(false);
        setShowSites(false);
        setCanResetAccount(false);
      } 
    }
    setIsCheckingAccount(false);
  }
  onLoad();
}, []);



useEffect(() => {

  async function onLoad() {
    if (isAuthenticated) {
      return;
    } else {
      const result = await getConfig();
      setFeaturedSites(result.favourites);
      setFeedback(result.feedback);
    }
  }
  onLoad();
}, []);

function getConfig() {
  return API.get("aires", `/sites/ALL/CONFIG`);
  //return API.get("aires", `/sitesadmin?isconfig=1`);
}

function getSites() {
  return API.get("aires", `/sites`);
}

function getUser() {
  return API.get("aires", `/users`);
}


function getAccount(accId) {
  return API.get("aires", `/customers/${accId}${IS_TEST_MODE?"?testmode=true":""}`);
}


async function continueOnboarding(accId) {
  setAccountId(accId);
  const result = await getAccount(accId);
  //console.log(accId);
  //console.log(result);
  if (!result.account.charges_enabled || !result.account.details_submitted || result.account.requirements.currently_due.length>0) {
    setRequiresAccountDetails(true);
    setCanShowSites(false);
    setShowSites(false);

    const user = await getUser();
    if (user.itemStatus && user.itemStatus > 0) {
      setCanResetAccount(false);
    } else {
      if (result.account.capabilities.card_payments && result.account.capabilities.transfers) {
        setCanResetAccount(true);
      } else {
        setCanResetAccount(false);
      }
    }
  } else {
    setRequiresAccountDetails(false);
    const result = await getSites();
    if (result.status && result.data && result.data.length>0) {
      result.data.forEach(site => {
        if (!(site.itemStatus2 && site.itemStatus2===1)) {
          setOrderSigns(true);
        }
      });
      setCanShowSites(true);
      setShowSites(true);
    } else {
      setCanShowSites(false);
      setShowSites(false);
      setAddFirstSite(true);
    }
  }
}



async function handleContinueRegistrationClick() {
  //console.log(accountId);
  setIsLoadingContReg(true);
  setIsLoading(true);
  const result = await getUser();
  const accId = result.user.itemId;
  console.log(result);
  if (accId && accId!="") {
    if (result.user.content.type=="standard") {
      //const resultLink = await linkCustomerStandard({id: accId, istestmode: IS_TEST_MODE}); // does not work for a standard account
      //let url = resultLink.loginLink.url;
      const url = `https://dashboard.stripe.com/settings/account`;
      if (url) {
        window.location.href = url;
      }
    } else {
      const resultLink = await linkCustomer({id: accId, url: window.location.protocol + "//" + window.location.host, istestmode: IS_TEST_MODE});
      let url = resultLink.accountLinks.url;
      if (url) {
        window.location.href = url;
      }
    }
    setIsLoading(false);
    setIsLoadingContReg(false);
  } else {
    setIsLoading(false);
    setIsLoadingContReg(false);

    setAlertOnboarding(true);
  }
}

async function onboardExistingStripeAccount(e) {
  e.preventDefault();
  setIsLoadingContReg(true);
  setIsLoading(true);
  const url = "https://connect.stripe.com/oauth/authorize?response_type=code&client_id=" + (IS_TEST_MODE?CLIENT_ID_TEST_MODE:CLIENT_ID_LIVE) + "&scope=read_write" + (IS_TEST_MODE?"&redirect_uri=http://localhost:3000":"");
  window.location.href = url;
  //setIsLoading(false);
  //setIsLoadingContReg(false);
}

async function onboardNewStripeAccount(e) {
  e.preventDefault();
  setIsLoadingContReg(true);
  setIsLoading(true);
  const resultAccount = await createCustomer({email: email, istestmode: IS_TEST_MODE});
  const resultLink = await linkCustomer({id: resultAccount.account.id, url: window.location.protocol + "//" + window.location.host, istestmode: IS_TEST_MODE});
  let url = resultLink.accountLinks.url;
  if (url) {
    window.location.href = url;
  }
  //setIsLoading(false);
  //setIsLoadingContReg(false);
}

function handleResetAccountClick(e) {
  e.preventDefault();
  setAlertRestart(true);
}

function handleAddFirstSiteClick(e) {
  e.preventDefault();
  history.push("sites/create/new");
}





async function resetAccount() {
  setIsLoadingReReg(true);
  setIsLoading(true);
  setAlertRestart(false);
  const resultLink = await recreateCustomer({previousAccountId: accountId, email: email, url: window.location.protocol + "//" + window.location.host});
  let url = resultLink.accountLinks.url;
  if (url) {
    window.location.href = url;
  }
  return;
  setIsLoading(false);
  setIsLoadingReReg(false);
}







function validateForm() {
  return !(fields.siteid.length > 0);
}


function handleScanClick(e) {
  setPauseScan(false);
  //setScanResult("CLEARED");
  setIsScanning(true);
}
  

function handleScanError(e) {
  setIsScanning(false);
  alertVals.title = "QR Code Scanner"
  if (e.code==8) {
    alertVals.message = "Camera not found."
  } else {
    alertVals.message = e.message;
  }
  setAlertOpen(true);
}

function handleScan(data) {
  if (!pauseScan && data) {
    setPauseScan(true);
    const parts=data.split("/");
    if (parts.length==5) {
      if (parts[2].indexOf("airestop.co.uk")>-1) {
        const siteId=parts[parts.length-1];
        openSite(siteId);
        setIsScanning(false);
      }
    }
    setPauseScan(false);
  }
}


function onScanClick(e) {
  //console.log(event)
  //event.preventDefault();
  setPauseScan(false);
  setIsScanning(true);
}



function onSiteIdClick(event, newSiteId) {
  event.preventDefault();
  //console.log(newSiteId)
  //setSiteId(newSiteId)
  //console.log(siteId)

  const siteId = newSiteId.trim().toUpperCase();
  openSite(siteId);

}


function handleSiteClick() {
  const siteId = fields.siteid.trim().toUpperCase();
  openSite(siteId);
}
  


async function openSite(siteId) {
  if (siteId.length>0) {
    setIsLoading(true);
    const result = await getSite(siteId);
    if (result.status==true) {
      if (isCampra) {
        history.push("/campra/" + siteId);
      } else {
        history.push("/sites/" + siteId);
      }
    } else {
      alertVals.title = "AireStop - Oops!"
      alertVals.message = "Unable to find an AireStop with that code."
      setAlertOpen(true);
    }
    setIsLoading(false);
  }
}




function handleSitesClick() {
  history.push("/sites");
}
function handleSignShopClick() {
  history.push("/shop");
}

function handleVisitorBookClick() {
  history.push("/visitors");
}








async function handleEmailClick() {
  setIsLoading(true);
  let result = await testmail();
  setIsLoading(false);
}


function cancelScanClick() {
  setIsScanning(false);
}


function testmail() {
  return API.post("aires", "/mail", {
    body: {}
  });
}



function getSite(code) {
  return API.get("aires", `/sites/${code}/EXISTS`);
}

// create user if not already exists then returns user
function createUser(body) {
  return API.post("aires", "/users", {
    body: body
  });
}

function onboardExisting(content) {
  return API.post("aires", "/customers", {
    body: {type: 'onboardexisting', content: content}
  });
}

function createCustomer(content) {
  return API.post("aires", "/customers", {
    body: {type: 'create', content: content}
  });
}
function recreateCustomer(content) {
  return API.post("aires", "/customers", {
    body: {type: 'recreate', content: content}
  });
}
function linkCustomer(content) {
  return API.post("aires", "/customers", {
    body: {type: 'link', content: content}
  });
}
function linkCustomerStandard(content) {
  return API.post("aires", "/customers", {
    body: {type: 'loginlink', content: content}
  });
}
/*
function getCustomer(content) {
  return API.post("aires", "/customers", {
    body: {type: 'get', content: content}
  });
}
  */

function handleAlertClose() {
  setAlertOnboarding(false);
  setAlertOpen(false);
  setAlertRestart(false);
};

function getDashboardLink() {
  return API.get("aires", `/customers/dashboard`);
}

async function handlePaymentsClick(event) {
  event.preventDefault();
  setIsLoadingPayments(true);
  setIsLoading(true);
  const result = await getDashboardLink();
  if (result && result.status==true) {
    let url = result.link.url;
    if (url) {
      window.location.href = url;
    }
  } else {
    // user not found (unlikely)
    history.push("/");
  }
  setIsLoading(false);
  setIsLoadingPayments(false);
}

const eventhandler = data => {fields.siteid=data.siteid }

function handleContactClick(event) {
  event.preventDefault();
  history.push(isAuthenticated?"/contactus":"/contact", { from: 'home' } );
}

function onMapClick(event) {
  event.preventDefault();
  history.push("/sites/map");
}



function renderCampra() {
  return (
    <>
    <div className={width>breakpoint?"lander2":"mobile-lander2"} >

    <h2 style={{color:"#10385A"}}>Welcome to the</h2>
    <h2 style={{color:"#F18C7E"}}>CAMpRA Accreditation Hub</h2>

    <img src={campraImage} alt="AireStop" width="100%" height="auto"/>


    <Box
    mt={4}
    display="flex"
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
    width="100%"
    >
    <SiteIdInput id="siteid"
    onChange={eventhandler}
    size="small"
    onClick={() => { handleSiteClick() }}
    />
    <Box p={1}>
    <Fab size="small" color="primary" aria-label="add" variant="extended" onClick={() => { handleScanClick() }}>
    <span class="material-icons-round" style={{ color: "#fff"}}>
      qr_code_scanner
    </span>
    <span style={{ color: "#fff"}}>
    &nbsp;Scan QR CODE
    </span>

    </Fab>
    </Box>
    </Box>

    <Box
    display="flex"
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
    width="100%"
    >
    <br/>
    <Box maxWidth="300px">

      <p>Welcome to AireStop. <br/><br/>
      This account should only be used by a CAMpRA representative with the authority to accredit Aires. Use by any one else is strictly forbidden.<br/><br/>
This special administration account enables you to apply or remove CAMpRA certified status from AireStops.  Simply search the site by scanning the QR code or entering the code, then toggle the certification switch to the desired setting.
</p>
    </Box>
    <br/><br/>










  </Box>








    </div>



    <Box
    position="sticky"
    bottom="0px"
    left="0px"
    right="0px"
    display="flex"
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
    width="100%"
    bgcolor="#10385A">
    <Divider width="100%" style={{ background: '#fff' }}/>


    <Box display="flex" p={1} width="100%">

    <Box p={0} flexGrow={1}>
    <FooterTypography variant="caption">AIRESTOP<sup>®️</sup> LIMITED<br/> {/* ® */}
    Company No. SC707418<br/>
    <Link style={{color:"white"}} onClick={handleContactClick}>Contact us</Link>
    </FooterTypography>
    </Box>
    <Box p={0} display="flex" flexDirection="column">
    <a style={{color:"white", fontSize:"12px", textAlign:"right"}} href={"https://airestop.co.uk/docs/faqs.pdf"} target="_blank">FAQs</a>
    <Box style={{textAlign:"right"}} py={0.5}><a style={{color:"white", fontSize:"12px", textAlign:"right"}} href={"https://airestop.co.uk/docs/privacy.pdf"} target="_blank">Privacy</a></Box>
    <a style={{color:"white", fontSize:"12px", textAlign:"right"}} href={"https://airestop.co.uk/docs/tncs.pdf"} target="_blank">Terms of Use</a>
    </Box>
    </Box>
    <Box width="100%" mt={-0.8} pl={1} style={{textAlign:"left"}}><a style={{color:"#ccc", fontSize:"10px", textAlign:"right"}} >©2025 AireStop Limited. All rights reserved.</a></Box>
    </Box>
</>

  );




}












function renderLander() {
  return (
    <div className={width>breakpoint?"lander":"mobile-lander"} >

    {isCampra?<><h2>Welcome</h2><h1>CAMpRA</h1><h2>Administrator</h2></>:<h1>find pay stay</h1>}



    <Box
    display="flex"
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
    width="100%"
    position="absolute"
    bottom="60px"
    left="0px"
    right="0px"

    >
    <SiteIdInput id="siteid"
    onChange={eventhandler}
    size="small"
    onClick={() => { handleSiteClick() }}
    />
    <Box p={1}>
    <Fab size="small" color="primary" aria-label="add" variant="extended" onClick={() => { handleScanClick() }}>
    <span class="material-icons-round" style={{ color: "#fff"}}>
      qr_code_scanner
    </span>
    <span style={{ color: "#fff"}}>
    &nbsp;Scan QR CODE
    </span>

    </Fab>
    </Box>
    </Box>

    <Box
    position="absolute"
    top="calc(100vh + 20px)"
    left="0px"
    right="0px"

    display="flex"
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
    width="100%"
    >
    <br/>
    <Box display="flex">

    <h1 style={{color:"#F18C7E", fontSize: "27px"}}>Welcome to&nbsp;</h1>
    <h1 style={{color:"#10385A", fontSize: "27px"}}>AireStop.</h1>
    </Box>
    <Box maxWidth="300px">
    {isCampra?
      <p>Welcome to the CAMpRA Accreditation Hub<br/><br/>
This account should only be used by a CAMpRA representative with the authority to accredit Aires. Use by any one else is strictly forbidden.<br/><br/>
This special administration account enables you to apply or remove CAMpRA certified status from AireStops.  Simply search the site by scanning the QR code or entering the code, then toggle the certification switch to the desired setting.
</p>
      :
      <p>For more information on your chosen Stop, or to make a payment, enter the site code in the search bar above.<br/><br/>
      To find out more about us, or to register a new <b>AireStop</b>, visit our menu at the top of the page.</p>
    }
    </Box>

    <br/><br/>

    <Box
    display="flex"
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
    width="100%"
    bgcolor="#10385A">
    <Divider width="100%" style={{ background: '#fff' }}/>

    <Box display="flex" px={1} pt={1} pb={0.5} width="100%"
>




    <Box p={0} flexGrow={1}>
    <FooterTypography variant="caption">AIRESTOP<sup>®️</sup> LIMITED<br/> {/* ® */}
    Company No. SC707418<br/>
    </FooterTypography>
    <Link style={{color:"white"}} onClick={handleContactClick}>Contact us</Link>
    </Box>
    <Box p={0} display="flex" flexDirection="column"
>
    <a style={{color:"white", fontSize:"12px", textAlign:"right"}} href={"https://airestop.co.uk/docs/faqs.pdf"} target="_blank">FAQs</a>
    <Box style={{textAlign:"right"}} py={0.5}><a style={{color:"white", fontSize:"12px", textAlign:"right"}} href={"https://airestop.co.uk/docs/privacy.pdf"} target="_blank">Privacy</a></Box>
    <a style={{color:"white", fontSize:"12px", textAlign:"right"}} href={"https://airestop.co.uk/docs/tncs.pdf"} target="_blank">Terms of Use</a>
    </Box>

</Box>
<Box width="100%" mt={-0.6} pl={1} style={{textAlign:"left"}}><a style={{color:"#ccc", fontSize:"10px", textAlign:"right"}} >©2025 AireStop Limited. All rights reserved.</a></Box>
    </Box>
    </Box>








    </div>






  );




}


function renderLander2() {
  return (
    <>
    <div className={width>breakpoint?"lander2":"mobile-lander2"} >
    {isCheckingAccount?
    <Box display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        width="100%"
        position="absolute"
        top="70px"
        left="0px"
        right="0px"
        >

    <div id="splash" style={{paddingTop: 100, zIndex:999}}>

    <center id="splash">
    <img src={spinner} height="30px" width="auto"/>
    </center>
    </div>
    </Box>
    :""}
    <h1 style={{color:"#10385A"}}>Welcome To Your</h1>
    <h1 style={{color:"#F18C7E"}}>Host Hub.</h1>

    <Box my={2}>
    <p>From this page you can access everything you need in just a few clicks.<br/><br/>If there is any important information that we need to bring to your attention or actions we need you to complete then we will display them here.</p>
    </Box>
    <Box p={0}
    display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              width="100%"

>
<Box p={0} style={{maxWidth: "300px"}}>


    {requiresAccountDetails?
      <>
      <Paper>
      <Box p={1}>
      <>
    <Box mb={1}><p>Please complete your registration. You need to do this before you can create your AireStop profile and start taking payments. Please view the <a style={{color:"#F18C7E"}} href={"https://airestop.co.uk/docs/registration_guide.pdf"} target="_blank"><u>Registration Guide</u></a> to help you through the process.</p></Box>
      <LoaderButtonX
      startIcon={<HowToRegRoundedIcon />}
      type="button"
      style={{background:"#F18C7E"}}
      isLoading={isLoadingContReg}
      disabled={false}
      onClick={!isLoading ? handleContinueRegistrationClick : null}
      >
      Complete Registration
      </LoaderButtonX>
      </>
      {canResetAccount?
        <>
        <br/><br/>
        <Box mb={1}><p>If you make a mistake whilst completing your registration you can restart here.</p></Box>

        <LoaderButtonX
        startIcon={<RestartAltRoundedIcon />}
        type="button"
        style={{background:"#F18C7E"}}
        isLoading={isLoadingReReg}
        disabled={false}
        onClick={!isLoading ? handleResetAccountClick : null}
        >
        Restart Registration
        </LoaderButtonX>
        </>
        :""}
        </Box>
      </Paper>
      <br/>
      </>
      : addFirstSite?
        <>




      <Paper>
      <Box p={1}>
      <>
      <Box mb={1}><p>Please add your first AireStop.</p></Box>
      <LoaderButtonX
      startIcon={    <span class="material-icons-round" style={{ color: "#fff"}}>
            add_location_alt
          </span>
}
      type="button"
      style={{background:"#F18C7E"}}
      isLoading={false}
      disabled={false}
      onClick={handleAddFirstSiteClick}
      >
      Add AireStop
      </LoaderButtonX>
      </>
        </Box>
      </Paper>
      <br/>
      </>
      : orderSigns?
      <>
      <Paper>
      <Box p={1}>
      <>
      <Box mb={1}><p>Order your free signs and other signage.</p></Box>
      <LoaderButtonX
      startIcon={    <span class="material-icons-round" style={{ color: "#fff"}}>
            follow_the_signs
          </span>
}
      type="button"
      style={{background:"#F18C7E"}}
      isLoading={false}
      disabled={false}
      onClick={handleSignShopClick}
      >
      Order Signage
      </LoaderButtonX>
      </>
        </Box>
      </Paper>
      <br/>
      </>
      :""


    }





      <Box p={1}>
      <LoaderButtonX
      type="button"
      isLoading={false}
      disabled={!canShowSites}
      onClick={!isLoading ? handleSitesClick : null}
      >
      My AireStops
      </LoaderButtonX>
<Box pt={1}>
      <LoaderButtonX
        type="button"
        isLoading={false}
        disabled={!canShowSites}
        onClick={!isLoading ? handleSignShopClick : null}
      >
        Sign Shop
      </LoaderButtonX>
</Box>
<Box py={1}>
<LoaderButtonX
      type="button"
      isLoading={false}
      disabled={!canShowSites}
      onClick={!isLoading ? handleVisitorBookClick : null}
      >
      Visitor Book
      </LoaderButtonX>
      </Box>
    <LoaderButtonX
      type="button"
      isLoading={isLoadingPayments}
      disabled={!canShowSites}
      onClick={!isLoading ? handlePaymentsClick : null}
    >
      Payments History
    </LoaderButtonX>
    </Box></Box></Box>

      </div>

      <Box
      position="sticky"
      bottom="0px"
      left="0px"
      right="0px"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      width="100%"
      bgcolor="#10385A">
      <Divider width="100%" style={{ background: '#fff' }}/>


      <Box display="flex" p={1} width="100%">

      <Box p={0} flexGrow={1}>
      <FooterTypography variant="caption">AIRESTOP<sup>®️</sup> LIMITED<br/> {/* ® */}
      Company No. SC707418<br/>
      <Link style={{color:"white"}} onClick={handleContactClick}>Contact us</Link>
      </FooterTypography>
      </Box>
      <Box p={0} display="flex" flexDirection="column">
      <a style={{color:"white", fontSize:"12px", textAlign:"right"}} href={"https://airestop.co.uk/docs/faqs.pdf"} target="_blank">FAQs</a>
      <Box style={{textAlign:"right"}} py={0.5}><a style={{color:"white", fontSize:"12px", textAlign:"right"}} href={"https://airestop.co.uk/docs/privacy.pdf"} target="_blank">Privacy</a></Box>
      <a style={{color:"white", fontSize:"12px", textAlign:"right"}} href={"https://airestop.co.uk/docs/tncs.pdf"} target="_blank">Terms of Use</a>
      </Box>
      </Box>
      <Box width="100%" mt={-0.8} pl={1} style={{textAlign:"left"}}><a style={{color:"#ccc", fontSize:"10px", textAlign:"right"}} >©2025 AireStop Limited. All rights reserved.</a></Box>
      </Box>


</>
    );
  }



        function renderQrReader() {
          return (
            <div>
            <Paper style={{padding: '4px'}}>
            <QrReader
            delay={300}
            onError={handleScanError}
            onScan={handleScan}
            style={{ width: '100%' }}
            />
            </Paper>
            <Box p={1}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            width="100%"
            >

            {scanResult}



            <Button

            type="button"
            size="sm"
            variant="outlined"
            isLoading={false}
            disabled={false}
            style={{width: "50%"}}
            onClick={cancelScanClick}
            >
            Cancel
            </Button>
            </Box>
            </div>
          )
        }


        function renderMotoroamerHome() {
          return (
            <>
            <HeroSectionSiteSearch 
            onScanClick={onScanClick}
            onSiteIdClick={onSiteIdClick}
            onMapClick={onMapClick}
            />
            <FeatureSectionHowItWorks />
            <FeatureSectionFeaturedSites featuredSites={featuredSites} onSiteIdClick={onSiteIdClick}/>
            <TestimonialSectionGuests feedback={feedback}/>
            <FooterPublicLrg />
            </>
          );
        }


        function renderLoader() {
          return (
            <div id="splash" style={{paddingTop: 100, zIndex:999}}>

              <center id="splash">
                <img src={spinner} height="30px" width="auto"/>
              </center>
            </div>


          );

        }

        return (
          <div className="Home">
          {
          //isAuthenticated && !isCampra?renderLander2():(isScanning?renderQrReader():(isLoading?renderLoader():(isCampra?renderCampra():renderLander())))
          isAuthenticated && !isCampra?renderLander2():(isScanning?renderQrReader():(isLoading?renderLoader():(isCampra?renderCampra():renderMotoroamerHome())))
          }
          
          <Dialog
          open={alertOpen}
          fullWidth={false}
          maxWidth = {'md'}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
          >
          <DialogTitle id="alert-dialog-slide-title" style={{color:"#10385A"}} >
          {alertVals.title}
          </DialogTitle>
          <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
          {alertVals.message}
          </DialogContentText>
          </DialogContent>
          <DialogActions>
          <Button onClick={handleAlertClose} color="secondary">
          Ok
          </Button>
          </DialogActions>
          </Dialog>
          <Dialog
          open={alertRestart}
          fullWidth={false}
          maxWidth = {'md'}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
          >
          <DialogTitle id="alert-dialog-slide-title" style={{color:"#10385A"}} >
          AireStop - Restart Registration
          </DialogTitle>
          <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
          Are you sure you wish to restart your registration. All existing registration details will be deleted.
          </DialogContentText>
          </DialogContent>
          <DialogActions>
          <Button onClick={handleAlertClose} color="primary">
          Cancel
          </Button>
          <Button onClick={resetAccount} color="secondary">
          Restart Registration
          </Button>
          </DialogActions>
          </Dialog>

          <Dialog
          open={alertOnboarding}
          fullWidth={false}
          maxWidth = {'md'}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
          >
          <DialogTitle id="alert-dialog-slide-title" style={{color:"#10385A"}} >
          Stripe Onboarding
          </DialogTitle>
          <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
          Do you have an existing Stripe account that you wish to use with AireStop or would you like to create a new Stripe account?
          </DialogContentText>
          </DialogContent>
          <DialogActions>
          <Button onClick={handleAlertClose} color="primary">
          Cancel
          </Button>
          <Button disabled={isLoadingContReg} onClick={onboardNewStripeAccount} color="secondary">
          Create New Stripe Account
          </Button>
          <Button disabled={isLoadingContReg} onClick={onboardExistingStripeAccount} color="secondary">
          Use My Existing Stripe Account
          </Button>
          </DialogActions>
          </Dialog>

          </div>
        );
      }
